<template>
  <v-container>
    <div class="footer">
      <h4 class="copyrightNotice">Copyright © Tait Communications 2024</h4>
      <h5
        v-if="links == 'true' && !showSelfHostedLicense"
        class="footerLinks"
        test-id="cloud_hosed_legal"
      >
        <a href="https://www.taitradio.com/privacy-policy" target="_blank"
          >Privacy</a
        >
        <span class="bulletSeparator">&bull;</span>
        <a
          href="https://www.taitradio.com/legal-notice-and-terms-of-use"
          target="_blank"
          >Legal Notice and Terms of Use</a
        >
        <span class="bulletSeparator">&bull;</span>
        <a href="https://www.taitradio.com/all-website-links" target="_blank"
          >All Tait websites</a
        >
      </h5>
      <h5
        v-if="links == 'true' && showSelfHostedLicense"
        class="footerLinks"
        test-id="self_hosed_legal"
      >
        By using this software, you agree to the
        <a href="/license.html" target="_blank"
          >Tait General Software License</a
        >
      </h5>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "LegalFooter",
  props: {
    links: {
      type: String,
      default: "false",
    },
  },
  data() {
    return {
      showSelfHostedLicense: false,
    };
  },
  created() {
    this.$store
      .dispatch("host/isSelfHosted")
      .then((selfHosted) => {
        if (selfHosted) {
          this.showSelfHostedLicense = true;
        }
      })
      .catch(() => {});
  },
};
</script>
